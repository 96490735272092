@import url(App.css);

body {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

label {
  background-color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content-container {
  width: 100%;
  flex: 1;  
  background-color: var(--gray-bg-lightest);
  padding-bottom: 2rem;
  justify-content: center;
}

.content-inner-container {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.content-box {
  border: 1px solid #e6e8e9;
  /* max-width: 600px; */
  margin: 3rem 0;
  padding: 3rem;
  text-align: left;
}

.content-box h1 {
  margin: 0;
  margin-bottom: 1rem;
}

.campusOptions {
  position: relative;
}

.campusOptions label:first-child {
  margin-right: 4rem;
}

.otherCampus {
  text-align: right;
  margin-top: 0.5rem;
}

.otherCampus a {
  background-color: red;
  padding: 1rem 2rem;
  text-decoration: none;
  color: white;
  margin-right: -3rem;
  display: inline-block;
  transition: 0.2s;
}

.otherCampus a:hover {
  opacity: 0.8;
  color: black;
}

.cs-btn {
  padding: 1rem;
  border: 1px solid var(--gray);
  transition: 0.2s;
  min-width: 65px;
}

.cs-btn .form-check-input {
  margin-left: 1.2rem;
}

.cs-btn:hover {
  cursor: pointer;
  opacity: 0.9;
}

.cs-btn-selected {
  background-color: blue;
  color: white;
}

.cs-btn-selected .form-check-input {
  background-color: white !important;
}

.help-ico {
  height: 1.5rem;
  width: 1.5rem;
  content: url('../public/images/ico-question.svg');
}

.fileUploadInner {
  padding: 20px;
  width: 200px;
  height: 200px;
  border: 1px dashed var(--blue--dark);
}

.additionalComments {
  width: 100%;
  max-width: 600px;
}

textarea {
  padding: 1rem;
}

textarea:focus {
  outline: none;
}

.no-pl {
  padding-left: 0 !important;
}

.sliitALEList {
  margin-bottom: 2rem;
}

.sliitALEList label {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sliitALEList label input {
  margin-right: 1.2rem;
}


.row {
  margin-bottom: 1rem;
}

.form-select {
  cursor: pointer !important;
}

.btn-help {
  outline: 1px solid var(--gray);
}

.cmd-bar {
  width: 100%;
  display: block;
  text-align: right;
  /* max-width: 600px; */
  padding-right: 2rem;
}

.modal-content {
  max-Width: 800px;
  width: 80vw;
}

.validationErrors {
  color: red;
  display: table;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 1.5rem;
}

.validationErrors span {
  display: block;
}

.noPadding {
  padding: 0 !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}

.reviewLink {
  color: blue;
  background-color: inherit;
  border: none;
  outline: none;
  padding: 0;
}

.reviewLink:hover {
  cursor: pointer;
}