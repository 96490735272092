:root {
  /* --font-size: 10px; */
  --black: #000;
  --body-black: #051c2a;
  --text-color: #4b4b4b;
  --black--bg: #191919;
  --blue--lightest: #caecf7;
  --blue: #1473ab;
  --blue--dark: #004b8b;
  --blue--link: #116191;
  --blue--portal: #22547c;
  --brown: #503110;
  --gap-element: 3rem;
  --gap-larger: 4rem;
  --gap-largest: 3.6rem;
  --gray--lightest: #f7f7f7;
  --gray--lighter: #f0f0f0;
  --gray--light: #e8e8e8;
  --gray: #b3b3b3;
  --gray--dark: #717171;
  --gray--darker: #4b4b4b;
  --gray--darkest: #303030;
  --gray--bg: #f7f7f7;
  --color-apple: #1de57e;
  --color-apple--light: #ddfbec;
  --color-lime: #c3d82e;
  --color-kiwi: #57d897;
  --color-banana: #fff3a8;
  --color-banana--light: #fbf4cf;
  --color-strawberry: #ff615e;
  --color-strawberry--light: #ffefef;
  --color-magenta: #e52592;
  --color-magenta-light: #fdeaf5;
  --color-magenta-dark: #ca2381;
  --color-lilac: #eeeafd;
  --color-deep-blue: #5525e5;
  --color-deep-blue--light: #f1eefd;
  --color-breadcrumb: #5f6d76;
  --fuchsia: #9b0664;
  --red: #af1800;
  --purple: #636;
  --yellow: #ffc000;
  --white: #fff;
  --facebook: #3c5193;
  --msngr: #009eff;
  --linkedin: #0077b5;
  --twitter: #37a7df;
  --youtube: #cc181e;
  --error: #ffecec;
  --success: #e9ffd9;
  --video-size: 740px;
  --gray-bg-lightest: #fcfcfc;
  --gap: 20px;
  --gap-half: 10px;
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px;  
  /* font-size: var(--font-size,10px);   */
}

.cRed {
  color: var(--red);
}

.tCenter {
  text-align: center;
}

input:focus {
  outline: inherit;
}

.App {
  text-align: center;
}

.error {
  color: red;
  display: block;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.header-container {
  position: sticky;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-content {
  flex: 1;
  text-align: right;
  align-items: center;
  align-items: center;
}

.sticky-header {
  position: fixed;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add other styles as needed */
}

.App-link {
  color: #61dafb;
}

.dobSelect {
  display: flex;
  margin: 0;
  padding: 0;
}

.dobItem {
  display: inline-flex;
}

.nav-logo {
  align-items: center;
  display: flex;
  line-height: 0;
  max-width: 200px;
}

.stage-container {
  display: block;
  background-color: white;
  padding: 2rem;
  border-bottom: 1px solid #e6e8e9;
  background-color: var(--gray--bg);
}

.stage-inner-container {
  display: flex;
  justify-content: center;
  width: 100%;  
}

.stage-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-breadcrumb);
  padding: 0 1rem;  
  /* font-size: 1.2rem; */
}

.stage-item-selected {
  font-weight: 600;
}

.stage-sep-container {
  display: flex;
  align-items: center;
}

.stage-sep {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent; /* Adjust arrow size */
  border-bottom: 5px solid transparent; /* Adjust arrow size */
  border-left: 8px solid #999; /* Adjust arrow color and size */
  margin: 0 8px; /* Adjust spacing as needed */  
}

.required::before {
  color: red;
  content: ' * ';
  margin-left: -12px;
  position: absolute;
  font-size: 2rem;
}

.block {
  display: block;
}

.checkContainer {
  cursor: pointer;
}

.mt2 {
  margin-top: 2rem;
}